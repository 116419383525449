import React from "react";
import "../styling/services.css";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import Image from "../assets/website.png";
import Image1 from "../assets/brand.png";
import Image2 from "../assets/smm.png";

const Services = () => {
  return (
    <div className="section services-container" id="services">
      <div className="section-intern">
        <div className="service service-one">
          <div className="image-holder">
            <img className="image" src={Image} alt="mockup" />
          </div>
          <div className="text-component">
            <h2 className="title-article">Website Development</h2>
            <h3 className="content-article">
              At Evolve, we offer bespoke web development services tailored to
              elevate your business's digital identity. By partnering with us,
              you'll harness innovative solutions that ensure your online
              presence not only meets but exceeds industry standards, propelling
              your brand to the forefront of your sector.
            </h3>
          </div>
        </div>
        <div className="service service-two">
          <div className="text-component">
            <h2 className="title-article">Branding</h2>
            <h3 className="content-article">
              We understand the power of a strong brand. By partnering with
              Evolve, you'll receive a personalized branding strategy that
              distinguishes your business, resonates with your audience, and
              enhances your position in the market. Stand out and make a lasting
              impression in your industry.
            </h3>
          </div>{" "}
          <div className="image-holder">
            <img className="image" src={Image1} alt="mockup" />
          </div>
        </div>
        <div className="service service-three">
          <div className="image-holder">
            <img className="image" src={Image2} alt="mockup" />
          </div>
          <div className="text-component">
            <h2 className="title-article">Social Media Management</h2>
            <h3 className="content-article">
              Unlock the full potential of your social media with Evolve. We
              offer comprehensive management services that enhance your online
              presence, engage your audience, and drive growth. Let us help you
              navigate the digital landscape effectively, keeping your brand
              vibrant and connected.
            </h3>
          </div>
        </div>
      </div>
      <div className="faq-section">
        <Accordion>
          <AccordionItem className="accordion" header="Why Evolve?">
            Because we work transparently, we are minimalists, we always seek to
            bring the desired results and to exceed them and above all because
            we have skin in the game, this makes us care about how our business
            is doing.
          </AccordionItem>

          <AccordionItem
            className="accordion"
            header="Yes, but some statistics?">
            1) Branding: Consistent branding across all platforms can increase
            revenue by up to 23%. Brands that are consistently presented are 3
            to 4 times more likely to experience brand visibility. Businesses
            without a robust online branding strategy see a brand recall
            reduction by up to 60%. <br />
            <br /> 2) Website: 75% of consumers admit to making judgments on a
            company’s credibility based on the company’s website design.
            Websites that are optimized for mobile devices see an average sales
            increase of 64%. Companies with outdated websites experience a
            30-40% decrease in potential customer engagement.
            <br /> <br /> 3) Social Media Management: Businesses that actively
            engage on social media enjoy an engagement boost of 250%.
            Additionally, 90% of marketers claim social media marketing has
            increased their business exposure. Lack of active social media
            presence can lead to a 70% decrease in customer interactions,
            potentially impacting customer loyalty and acquisition.
          </AccordionItem>

          <AccordionItem
            className="accordion"
            header="What does the collaboration consist of?">
            We join forces! This means that we will make a partnership between
            companies, this involves us 100% in your project. We take over the
            digital part, we take care of it entirely based on your ideas, after
            all you are the business owner.
          </AccordionItem>
          <AccordionItem
            className="accordion"
            header="How does the process work?">
            After you contact us, we schedule a call to get to know each other,
            we go through certain stages through which we understand your needs,
            we explain what we will do and how, then we move on to the legal
            part, once this process is completed, we start the collaboration.
          </AccordionItem>
          {/* <AccordionItem header="What does the collaboration consist of?">
            We join forces! This means that we will make a partnership between
            companies, this involves us 100% in your project. We take over the
            digital part, we take care of it entirely based on your ideas, after
            all you are the business owner.
          </AccordionItem> */}
        </Accordion>
      </div>
      <div className="contact-us">
        Want to be a partner?
        <div className="contacts">
          <h4 className="span-contact">
            <a href="mailto:evolve@upvisionmedia.com">Mail</a>
          </h4>
          <div className="socials">
            <h4>
              <a href="https://instagram.com/upvisionmediagency" target="blank">
                Instagram
              </a>
            </h4>
            <h4>
              <a
                target="blank"
                href="https://www.facebook.com/profile.php?viewas=100000686899395&id=100087936925363">
                Facebook
              </a>
            </h4>
            <h4>
              <a
                target="blank"
                href="https://linkedin.com/company/upvisionmedia">
                LinkedIn
              </a>
            </h4>
          </div>
        </div>
        <h5 className="footer-logo">
          <a
            target="blank"
            style={{ textDecoration: "none" }}
            href="upvisionmedia.com">
            upvisionmedia.com
          </a>
        </h5>
      </div>
      <a href="#home" className="back-home-button">
        back home
      </a>
    </div>
  );
};

export default Services;
