import { ParallaxProvider } from "react-scroll-parallax";
import "../src/styling/App.css";
import Home from "./pages/Home";
import Services from "./pages/Services";

function App() {
  return (
    <ParallaxProvider>
      <div className="App">
        <Home />
        <Services />
      </div>
    </ParallaxProvider>
  );
}

export default App;
