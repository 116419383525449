import React from "react";
import "../styling/home.css";
import TypewriterComponent from "typewriter-effect";
import { useParallax } from "react-scroll-parallax";

const Home = () => {
  const parallaxLetter = useParallax({
    speed: -5,
  });

  return (
    <div className="section home-container" id="home">
      <div className="header-home">
        <h1 ref={parallaxLetter.ref} className="header-h1">
          evolv
          <span className="text" aria-hidden="true">
            ǝ
          </span>
        </h1>
        <h2 className="header-h2">
          <TypewriterComponent
            options={{
              strings: ["scaling digital frontiers"],
              autoStart: true,
              loop: true,
              delay: 200,
              pauseFor: 5000,
            }}
          />
        </h2>
      </div>
      <a
        href="#services"
        className="scroll-down-btn"
        aria-label="Scroll Down"></a>
    </div>
  );
};

export default Home;
